import React from "react";
import DesignOpt1 from "./design-services/DesignOpt1";
import DesignOpt2 from "./design-services/DesignOpt2";
import DesignOpt3 from "./design-services/DesignOpt3";
import DesignOpt4 from "./design-services/DesignOpt4";

const DesignServices = ({ uid }) => {
  switch (uid) {
    case "DesignOpt1":
      return <DesignOpt1 />;
    case "DesignOpt2":
      return <DesignOpt2 />;
    case "DesignOpt3":
      return <DesignOpt3 />;
    case "DesignOpt4":
      return <DesignOpt4 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default DesignServices;
