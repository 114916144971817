import React from "react";
import DecrementButton from "../buttons/decrementButton";
import IncrementButton from "../buttons/incrementButton";

import "./_qunatity.scss";

const Quantity = ({
  state,
  stepCalcText,
  handleStepChanges,
  error,
  displaytotal,
}) => {
  var displayExtraFees =
    state.product.launchFee ||
    state.product.initialFee.enabled ||
    state.product.projectFee
      ? true
      : false;

  const { addonsTotal, initialFee } = state;
  const { value, min, max } = state.quantity;
  const { amount } = state;
  const { price, launchFee, projectFee } = state.product;
  const step = state.product.quantity["step"];
  let quantity = state.quantity.value;
  const initialFeeStep = 100;

  const handleDecrement = () => {
    if (value > min) {
      handleStepChanges("quantity", {
        value: value - step,
      });
      handleStepChanges("amount", amount > 0 ? amount - price * step : price);
      handleStepChanges(
        "totalAmount",
        (price * (value - step) +
          launchFee +
          projectFee +
          initialFee.value +
          addonsTotal) *
          1.05
      );
    }
  };

  const handleIncrement = () => {
    if (quantity < max) {
      handleStepChanges("quantity", {
        value: quantity + step,
      });
      handleStepChanges(
        "amount",
        price * (quantity + step) + launchFee + projectFee + initialFee.value
      );
      handleStepChanges(
        "totalAmount",
        (price * (quantity + step) +
          launchFee +
          projectFee +
          initialFee.value +
          addonsTotal) *
          1.05
      );
    }
  };

  const handleQuantityChange = (e) => {
    var value = Number(e.target.value.replace(/\D/, ""));
    value = value < max ? value : max;
    value = value < min ? min : value;

    handleStepChanges("quantity", {
      value: value,
    });
    handleStepChanges(
      "amount",
      value * price + launchFee + projectFee + initialFee.value
    );
    handleStepChanges(
      "totalAmount",

      (value * price + launchFee + projectFee + initialFee.value) * 1.05
    );
  };

  //handle initial fee change
  const handleDecrementInitialfee = () => {
    if (initialFee.value - initialFeeStep >= initialFee.min) {
      handleStepChanges("initialFee", {
        value: initialFee.value - initialFeeStep,
      });

      handleStepChanges("amount", amount - initialFeeStep);
    }
  };

  const handleIncrementInitialfee = () => {
    handleStepChanges("initialFee", {
      value: initialFee.value + initialFeeStep,
    });

    handleStepChanges("amount", amount + initialFeeStep);
    handleStepChanges("totalAmount", (amount + initialFeeStep) * 1.05);
  };
  const renderPrompt = (param) => {
    switch (param) {
      case "photo-1":
        return <>How many photos?</>;
      case "photo-2":
        return (
          <>
            How many unique
            <br /> products or SKUs?
          </>
        );
      case "photo-3":
        return "Minimum order of 5 photos";
      case "studio-1h-150":
      case "support-emergency":
        return (
          <>
            Enter number of <br /> hours 2-10
          </>
        );
      case "support-additional":
      case "cmo-hr":
        return (
          <>
            Enter number of <br /> hours
          </>
        );
      default:
        return (
          <>
            Enter number of <br /> Months to start
          </>
        );
    }
  };
  var extrafees = "";
  if (displayExtraFees) {
    extrafees = (
      <>
        <div className="checkout-plus-icon">+</div>
        {state.product.initialFee.enabled ? (
          <>
            <div className="checkout-quantity">
              <div className="items-count-group">
                <DecrementButton onClick={handleDecrementInitialfee} />
                <input
                  type="text"
                  id="initial-fee"
                  name="initialFee"
                  maxLength="5"
                  value={initialFee.value}
                  readOnly={true}
                />
                <IncrementButton onClick={handleIncrementInitialfee} />
                {error.quantity && (
                  <small className="help-block with-errors">
                    {error.quantity}
                  </small>
                )}
              </div>

              <p>Initial monthly ad spend</p>
            </div>

            <div className="checkout-plus-icon">+</div>
          </>
        ) : (
          ""
        )}
        {launchFee ? (
          <div className="checkout-amount">
            <input
              type="text"
              id="launch-fee"
              value={`$${launchFee.toLocaleString()}`}
              readOnly={true}
            />
            <p>Launch fee </p>
          </div>
        ) : (
          ""
        )}
        {projectFee ? (
          <div className="checkout-amount">
            <input
              type="text"
              id="project-fee"
              value={`$${projectFee.toLocaleString()}`}
              readOnly={true}
            />
            <p>Project Fee </p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  return (
    <div className="checkout-quantity-column">
      {stepCalcText ? (
        <h4 className="heading">{stepCalcText}</h4>
      ) : state.product.type === "product-photography" ? (
        <h4 className="heading">Enter Numbers of Photos</h4>
      ) : (
        <h4 className="heading">Number of hours needed</h4>
      )}
      <div className="checkout-quantity-component">
        <div className="checkout-quantity">
          <div className="items-count-group">
            <DecrementButton onClick={handleDecrement} />
            <input
              type="text"
              name="quantity"
              maxLength="2"
              onChange={handleQuantityChange}
              value={value}
              readOnly={false}
            />
            <IncrementButton onClick={handleIncrement} />
            {error.quantity && (
              <small className="help-block with-errors">{error.quantity}</small>
            )}
          </div>

          <p>{renderPrompt(state.product.id)}</p>
        </div>

        {extrafees}
        {displaytotal && (
          <>
            <div className="checkout-plus-icon">=</div>
            <div className="checkout-amount">
              <input
                type="text"
                id="amount"
                value={`$${amount.toLocaleString()}`}
                readOnly={true}
              />
              <p>Total (+tax)</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Quantity;
