const api = process.env.GATSBY_API_HUBSPOT;

export const createCalendarTask = (state) => {
  const selectedDay = state.calendar.selectedDay.toLocaleDateString();
  const date = Date.parse(
    `${selectedDay} ${state.calendar.selectedTime} UTC -7`
  );

  return fetch(`${api}/createCalendarTask`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      eventDate: date,
      name: `HSL - ${state.project.name}`,
      description: `HSML Booking - category product: ${state.product.title}`,
      url: "https://thesimpledepartment.com/",
      createdBy: "541935",
      ownerId: "",
      state: "TODO",
      category: "LANDING_PAGE",
    }),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};

export const createHubspotFormSubmission = (state) => {
  return fetch(`${api}/formsSubmit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      formId: state.hubspot.formId,
      company: state.project.company,
      full_name: state.billing_details.name,
      product:
        state.product.checkout.title + " | " + state.product.checkout.subtitle,
      email: state.project.email,
      phone: state.billing_details.phone,
      country: state.billing_details.country,
      city: state.billing_details.city,
      address: state.billing_details.address,
      zip: state.billing_details.zip,
    }),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};

export const createHubspotContact = (state, stripeData) => {
  return fetch(`${api}/createContact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      changedAt: stripeData.livemode
        ? stripeData.created
        : new Date().getTime(),
      name: state.billing_details.name,
      email: state.billing_details.email
        ? state.billing_details.email
        : state.project.email,
      phone: state.billing_details.phone,
    }),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};

export const createHubspotDeal = (state, stripeData) => {
  return fetch(`${api}/createDeal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      changedAt: stripeData.livemode
        ? stripeData.created
        : new Date().getTime(),
      externalObjectId: "deal_" + state.id,
      dealname: state.product.category + " - " + state.product.title,
      dealstage: "checkout_completed",
      amount: state.amount,
      purchase_date: stripeData.livemode
        ? stripeData.created
        : new Date().getTime(),
      contact: state.billing_details.email
        ? state.billing_details.email
        : state.project.email,
    }),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};

export const createHubspotLineItem = (state, stripeData) => {
  console.log(state.deal);

  return fetch(`${api}/createLineItem`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      changedAt: stripeData.livemode
        ? stripeData.created
        : new Date().getTime(),
      externalObjectId: "lineItem_" + state.id,
      name: state.product.category + " - " + state.product.title,
      price: state.product.price,
      quantity: state.quantity.value,
      deal: "deal_" + state.id,
      product: state.product.id,
    }),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};
