import React from "react";

const AddAddonsBtn = ({ onClick, btnCopy }) => {
  return (
    <div className="btn addons-checkout-btn" onClick={onClick}>
      {btnCopy}
    </div>
  );
};

export default AddAddonsBtn;
