import React from "react";

const EmailMonthlyOpt1 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>1 x 15-minute planning call with our team</li>
      <li>
        1 x email newsletter [inclusive of planning, copywriting, design, build
        + scheduling]
      </li>
      <li>
        1 x promo email [inclusive of planning, copywriting, design, build +
        scheduling]
      </li>
      <li>1 x 15-minute review call with our team</li>
    </ul>
    <p>
      <strong>
        We recommend an initial 3-month agreement.{" "}
        <span id="intercombot-link">Ask us why</span>
      </strong>
    </p>
  </>
);
export default EmailMonthlyOpt1;
