import React from "react";

const DigitalAdvertisingOpt2 = () => (
  <>
    <h5 className="heading">MONTHLY DELIVERABLES:</h5>
    <ul>
      <li>Monthly campaign goals, campaign messaging & paid strategy</li>
      <li>Keyword & competitive research </li>
      <li>
        4 visual ads (tweaked & adjusted for 3 key channels like
        Facebook/Instagram + Pinterest + Google), including 1 retargeting ad set
      </li>
      <li>5 Google search ads</li>
      <li>1 SEO-optimized key landing page</li>
      <li>Campaign build & launch</li>
      <li>Ongoing weekly campaign management, monitoring & optimization</li>
      <li>Biweekly review meetings</li>
      <li>
        Monthly debrief on the campaign (evaluating and scoring the results &
        identifying opportunities, learning & changes that need to happen with
        the creative, budget, targeting & channel mix)
      </li>
    </ul>

    <p>
      <strong>
        <i>We recommend a 6-month agreement to see true, accelerated growth.</i>
      </strong>
    </p>
  </>
);
export default DigitalAdvertisingOpt2;
