import React from "react";
import AddonsItems from "../../../products/AddonsItems";

const SupportAddons = ({ state, handleStepChanges, updateState }) => {
  return (
    <section className="">
      <AddonsItems
        type="outline"
        state={state}
        handleStepChanges={handleStepChanges}
        updateState={updateState}
      >
        <div
          title="Additional Support Hours"
          subtitle="$175 per hour, booked in blocks of 3 hours."
          id="support-additional"
          srclink="/services/addons/additional-support-hours"
          price="175"
          quantity={true}
          interval="3"
        ></div>
      </AddonsItems>
    </section>
  );
};

export default SupportAddons;
