import React from "react";

const DigitalAdvertisingOpt3 = () => (
  <>
    <h5 className="heading">MONTHLY DELIVERABLES:</h5>
    <ul>
      <li>
        Monthly & quarterly content goals, messaging strategy &
        identification/recommendations of paid sponsorship of organic content
      </li>
      <li>
        Planning & management of micro influencer/partnership campaigns (does
        not include media or influencer spend)
      </li>
      <li>Average of 5 social posts a week on key channels</li>
      <li>Scheduling of all social content through Later</li>
      <li>
        2 SEO optimized blog posts a month, published on your website & shared
        on social
      </li>
      <li>Biweekly check-in meetings</li>
      <li>
        12-15 Instagram/Facebook stories per month, to be planned & scheduled
        for highest impact
      </li>
    </ul>
    <p>
      <strong>
        <i>
          We recommend an initial 12-month agreement to harness, measure &
          optimize the growth of your organic channels.
        </i>
      </strong>
    </p>
  </>
);
export default DigitalAdvertisingOpt3;
