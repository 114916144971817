import React from "react";
import ContentPROpt1 from "./content-pr-strategy/ContentPROpt1";
import ContentPROpt2 from "./content-pr-strategy/ContentPROpt2";
import ContentPROpt3 from "./content-pr-strategy/ContentPROpt3";

const ContentPRStrategyDescription = ({ uid }) => {
  switch (uid) {
    case "SmallContentPackage":
      return <ContentPROpt1 />;
    case "MediumContentPackage":
      return <ContentPROpt2 />;
    case "LargeContentPackage":
      return <ContentPROpt3 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default ContentPRStrategyDescription;
