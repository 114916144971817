import React from "react";

const MediumSEOPackage = () => (
  <>
    <h5 className="heading">MONTHLY DELIVERABLES:</h5>
    <ul>
      <li>1 x 30-minute strategy review call</li>
      <li>Keyword research</li>
      <li>Competitor research</li>
      <li>Monthly content strategy, goals & plan, including benchmarking</li>
      <li>
        1 x hours of webmaster & IT support for technical SEO improvements
      </li>
      <li>
        2 x hours of SEO content writing services for improvements on existing
        copy
      </li>
      <li>
        1 x SEOptimized Blog including research, copywriting, blog graphics &
        publishing
      </li>
      <li>45 minutes of backlink creation support</li>
      <li>Monthly site health check (content & technical)</li>
      <li>1 x 15-minute monthly debrief call</li>
    </ul>
  </>
);
export default MediumSEOPackage;
