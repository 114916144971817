export default function validate(values) {
  let errors = {};

  // if (!values.email) {
  //   errors.email = "Email address is required";
  // } else if (!/\S+@\S+\.\S+/.test(values.email)) {
  //   errors.email = "Email address is invalid";
  // }

  if (!values.company) {
    errors.company = "Company Name is required";
  } else if (values.company.length < 3) {
    errors.company = "Company Name must be 3 or more characters";
  }

  if (!values.name) {
    errors.name = "Project Name is required";
  } else if (values.name.length < 3) {
    errors.name = "Project Name must be 3 or more characters";
  }
  if (!values.firstname) {
    errors.firstname = "First Name is required";
  }
  if (!values.lastname) {
    errors.lastname = "Last Name is required";
  }

  return errors;
}
