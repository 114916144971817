import React from "react";

const ProductPhotosOpt3 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      5-10 (please select desired amount) high-res, optimized product photos,
      perfect for use on your website, ecommerce platforms, social media shops
      and catalogs or even print projects. Photos will all have a white
      background for maximum flexibility unless otherwise requested.
    </p>
  </>
);
export default ProductPhotosOpt3;
