import React, { useState } from "react";
import "./_modal.scss";
import close from "~static/svg/icons/close.svg";

const Modal = ({ title, button, children }) => {
  const [state, setState] = useState(false);

  const showModal = () => {
    setState(true);
  };

  const hideModal = () => {
    setState(false);
  };

  const showHideClassName = state
    ? "modal display-block"
    : "modal display-none";

  return (
    <>
      <button type="button" className="btn-modal" onClick={showModal}>
        {button ? <>{button}</> : <>Button text</>}
      </button>
      <div className={showHideClassName}>
        <div className="modal-dialog">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button className="close-modal" onClick={hideModal}>
              <img src={close} alt="close" width="25" height="25" />
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;
