import React from "react";

const StudioFullDayOpt2 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      Check 1, 2, 3! Our equipment package includes everything you need to run
      your show/presentation/production in our studio including lights, cameras
      and audio equipment.
    </p>
    <p>
      <i>
        This booking will include 8 hours of recording time plus 1 hour of
        pre-booking time to ensure microphones are connected and attached to
        you/your guests, cameras and lighting are adapted for your guests, etc,
        for a total booking time of 9 hours.
      </i>
    </p>
  </>
);

export default StudioFullDayOpt2;
