import React from "react";

const DigitalAdvertisingOpt3 = () => (
  <>
    <h5 className="heading">MONTHLY DELIVERABLES:</h5>
    <ul>
      <li>Monthly campaign goals, campaign messaging & paid strategy</li>
      <li>
        Creation, sourcing & management of 1 influencer/partnership campaign
        (influencer spend in addition to ad spend)
      </li>
      <li>Keyword & competitive research </li>
      <li>
        6 visual ads (tweaked & adjusted for 3 key channels like
        Facebook/Instagram + Pinterest + Google), including 2 retargeting ad
        sets
      </li>
      <li>8 Google search ads</li>
      <li>1 SEO-optimized key landing page </li>
      <li>Campaign build & launch </li>
      <li>Ongoing weekly campaign management, monitoring & optimization </li>
      <li>Biweekly review meetings </li>
      <li>
        Monthly debrief on the campaign including a data deep dive into outcomes
        & audiences, evaluating and scoring the results & identifying
        opportunities, learning & changes that need to happen with the creative,
        budget, targeting & channel mix.
      </li>
    </ul>

    <p>
      <strong>
        <i>
          We recommend a 12-month agreement to see consistent, scalable results.
        </i>
      </strong>
    </p>
  </>
);
export default DigitalAdvertisingOpt3;
