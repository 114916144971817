import React from "react";
import "../../../../styles/pages/_stripeinfo.scss";
import StripeIcon from "../../../../images/icons/Stripe.png";
import PayPalIcon from "../../../../images/icons/PayPal.png";
import InteracIcon from "../../../../images/icons/InteracLogo.png";
import AmexIcon from "../../../../images/icons/card-amex.svg";
import DinersclubIcon from "../../../../images/icons/card-dinersclub.svg";
import DiscoverIcon from "../../../../images/icons/card-discover.svg";
import JcbIcon from "../../../../images/icons/card-jcb.svg";
import MastercardIcon from "../../../../images/icons/card-mastercard.svg";
import VisaIcon from "../../../../images/icons/card-visa.svg";

const StripeInfo = () => {
  return (
    <div className="stripe-info-wrapper">
      <h3>Did you know?</h3>
      <p className="text-blue">
        Did you know that our payment stack is powered by{" "}
        <img src={StripeIcon} alt="" className="payment-icon" />?
      </p>

      <p>
        That means your payment information and our transactions are kept secure
        and validated for fraud risk. We also offer{" "}
        <img src={InteracIcon} alt="" className="payment-icon" /> for our
        Canadian based clients and have{" "}
        <img src={PayPalIcon} alt="" className="payment-icon" /> set up as an
        alternative option for one-time and recurring payments.<br></br>If you
        are curious how we custom built our own tech-stack and e-commerce
        solution without using something like Magento, WooCommerce, BigCommerce,
        Shopify etc… just let us know on our next call together and we would be
        happy to walk you through our technical and product roadmap.
      </p>
      <p className="text-blue">
        <strong>We Accept</strong>
      </p>
      <div className="payment-logos-list">
        <img src={InteracIcon} alt="" />
        <img src={VisaIcon} alt="" />
        <img src={AmexIcon} alt="" />
        <img src={DinersclubIcon} alt="" />
        <img src={DiscoverIcon} alt="" />
        <img src={JcbIcon} alt="" />
        <img src={MastercardIcon} alt="" />
      </div>
    </div>
  );
};
export default StripeInfo;
