import React from "react";
import AddonsItems from "../../../products/AddonsItems";

const SEOAddons = ({ state, handleStepChanges, updateState }) => {
  return (
    <section className="">
      <AddonsItems
        type="outline"
        state={state}
        handleStepChanges={handleStepChanges}
        updateState={updateState}
      >
        <div
          title="SEOptimized Blog"
          subtitle="$625 per blog"
          id="seo-optimized-blog"
          srclink="/services/addons/seo-optimized-blog"
          price="625"
          quantity={true}
          interval="1"
        ></div>
        <div
          title="SEOptimized Blog Series | 3 Blogs"
          subtitle="$1,500 per series"
          id="seo-optimized-blog-series"
          srclink="/services/addons/seo-optimized-blog-series"
          price="1500"
          quantity={true}
          interval="1"
        ></div>
        <div
          title="Additional SEO Content Support Hours"
          subtitle="$85 per hour"
          id="seo-content-support-hours"
          srclink="/services/addons/seo-content-support-hours"
          price="85"
          quantity={true}
          interval="1"
        ></div>
        <div
          title="Additional SEO Technical Support Hours"
          subtitle="$175 per hour"
          id="seo-technical-support-hours"
          srclink="/services/addons/seo-technical-support-hours"
          price="175"
          quantity={true}
          interval="1"
        ></div>
      </AddonsItems>
    </section>
  );
};

export default SEOAddons;
