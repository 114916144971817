import React from "react";
const DesignOpt4 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>1-hour consultation with our project manager</li>
      <li>Design brief</li>
    </ul>
    <p></p>
  </>
);
export default DesignOpt4;
