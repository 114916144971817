import React from "react";

const HireCmoOpt6 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      In this session, you are quickly presenting what you are selling, how you
      are going to sell it, who wants to buy it and how you expect all of that
      to happen with your teeny-weeny budget.
    </p>
    <p>
      We don’t pull punches. We’ll tell you straight up what we think and why.
      It will save you time and money because we can point to failures we have
      seen from the thousands of brands we have worked with – including business
      owners who have walked through our doors with the next billion-dollar idea
      – and draw upon our own campaign successes and duds.
    </p>
    <p>
      <strong>If you are nervous, it means we need to talk.</strong>
    </p>
    <p>
      <i>
        Please note, additional services like design, copywriting and web
        development can be purchased separately. Paid media spend will be in
        addition.
      </i>
    </p>
  </>
);
export default HireCmoOpt6;
