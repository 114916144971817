import React from "react";

const StudioHourlyOpt2 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      Check 1, 2, 3! Our equipment package includes everything you need to run
      your show/presentation/production in our studio including lights, cameras
      and audio equipment.
    </p>
  </>
);

export default StudioHourlyOpt2;
