import React from "react";

const CartSummary = ({ state }) => {
  const { totalAmount, amount, addonsList, addonsTotal } = state;

  const optionalAddons = addonsList.map((item, index) => {
    return (
      <div key={item[0]}>
        <span className="addon-detail">
          <span className="total-title-detail">{item[3]}: </span>${item[2]} CAD
          * {item[1]} (${(item[2] * item[1]).toFixed(2)} CAD)
        </span>
      </div>
    );
  });
  return (
    <>
      <div className="payment-form-wrapper">
        <div className="payment-details">
          <span className="payment-amount">
            <span className="total-title">{state.product.title}: </span>$
            {amount % 1 === 0 ? amount + ".00" : amount} CAD
          </span>
          {addonsList.length !== 0 ? (
            <span className="total-title">Optional Add-Ons: </span>
          ) : null}
          <span className="payment-addons">{optionalAddons}</span>
          <span className="payment-gst">
            <span className="total-title">GST: </span>$
            {(totalAmount - amount - addonsTotal).toFixed(2)} CAD
          </span>
          <span className="payment-total-amount">
            <span className="total-title">Total payment due: </span>$
            {totalAmount.toFixed(2)} CAD
          </span>
        </div>
      </div>
    </>
  );
};

export default CartSummary;
