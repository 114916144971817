import React from "react";
import { Link } from "gatsby";

const EmailMonthlyOpt2 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>1 x 30-minute planning call with our team</li>
      <li>
        1 x email newsletter [inclusive of planning, copywriting, design, build
        + scheduling]
      </li>
      <li>
        2 x promo email [inclusive of planning, copywriting, design, build +
        scheduling]
      </li>
      <li>1 x automated flow including:</li>
      <ul className="ul-circle" style={{ "margin-bottom": 0 }}>
        <li>Email content plan & strategy for 3-4 emails</li>
        <li>Email flow content outlines & copywriting</li>
        <li>Creation of all email graphics</li>
        <li>Build of all emails</li>
        <li>
          Creation of automated workflow, including integrations + triggers
        </li>
        <li>Pre-flight bug check & live launch</li>
      </ul>
      <li>1 x 15-minute review call with our team </li>
    </ul>
    <p>
      <strong>
        We recommend an initial 3-month agreement.{" "}
        <span id="intercombot-link">Ask us why</span>
      </strong>
    </p>
  </>
);
export default EmailMonthlyOpt2;
