import React from "react";

const HireCmoOpt1 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      Not sure where to start or what you need? Book time with your new
      fractional CMO to save time, stress, and money. No matter what is on your
      mind (related to your business that is, our CMO is not so good with the
      crushing existential dread that your parents gave you), our CMO can help
      you make impactful, intelligent decisions that work with your budget,
      goals, and schedule.
    </p>
    <p>
      We have plenty of data, stories, highlights and lowlights to share with
      you as we have worked with every industry from fitness to accounting to
      cannabis. It would be modest to say that we have learned a thing or two.
      We would be thrilled to share these insights with you and bring forward
      relevant examples, ideas, and showcases to demonstrate more of how we
      think and work.
    </p>
    <p>
      <strong>
        Put simply, everything starts with a conversation – how can we help?
      </strong>
    </p>
    <p>
      <i>
        Please note, additional services like design, copywriting and web
        development can be purchased separately. Paid media spend will be in
        addition.
      </i>
    </p>
  </>
);
export default HireCmoOpt1;
