import React from "react";
import SEOOpt1 from "./seo-services/SEOOpt1";

const SEOServicesDescription = ({ uid }) => {
  switch (uid) {
    case "SEOAudit":
      return <SEOOpt1 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default SEOServicesDescription;
