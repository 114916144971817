import React from "react";
import WebsiteAddons from "./websiteAddons";
import SupportAddons from "./supportAddon";
import EmailSetupAddons from "./emailSetupAddon";
import EmailMonthlyAddons from "./emailMonthlyAddon";
import SEOAddons from "./seoAddons";

import "./_addons.scss";

const Addons = ({ type, state, handleStepChanges, updateState }) => {
  const addonItem = () => {
    switch (type) {
      case "website-build":
        return (
          <WebsiteAddons
            state={state}
            handleStepChanges={handleStepChanges}
            updateState={updateState}
          />
        );
      case "web-it-support":
        return (
          <SupportAddons
            state={state}
            handleStepChanges={handleStepChanges}
            updateState={updateState}
          />
        );

      case "email-marketing-monthly":
        return (
          <EmailMonthlyAddons
            state={state}
            handleStepChanges={handleStepChanges}
            updateState={updateState}
          />
        );
      case "email-marketing-setup":
        return (
          <EmailSetupAddons
            state={state}
            handleStepChanges={handleStepChanges}
            updateState={updateState}
          />
        );
      case "seo":
        return (
          <SEOAddons
            state={state}
            handleStepChanges={handleStepChanges}
            updateState={updateState}
          />
        );
      default:
        return <h1>No project match</h1>;
    }
  };
  return (
    <div className="addons-wrapper">
      <h2 className="addons-header">OPTIONAL ADD-ONS:</h2>
      {addonItem()}
    </div>
  );
};
export default Addons;
