import React, { Component } from "react";
import ReactDOM from "react-dom";
import StepFormDetails from "./steps/details/stepFormDetails";
import StepFormDetailsCombined from "./steps/details_combined/details_combined";
import StepSubscriptionPlan from "./steps/subscriptionPlan/subscriptionPlan";
import StepEventForm from "./steps/eventForm/EventForm";
import StepProjectDetails from "./steps/projectDetails/stepProjectDetails";
import StepFormPayment from "./steps/paymentForm/PaymentForm";
import CartSummary from "./steps/cart-summary";
import "./_checkout-steps.scss";
import CheckoutHeading from "./CheckoutHeading";
//PayPal
var PayPalButton = "";
const style = {
  layout: "vertical",
  color: "blue",
  shape: "pill",
  label: "pay",
  height: 50,
};

export class checkoutSteps extends Component {
  constructor(props) {
    super(props);
    const amount =
      props.product.quantity.min > 1
        ? props.product.quantity.min * props.product.price +
          props.product.initialFee.min +
          props.product.launchFee +
          props.product.projectFee
        : props.product.price +
          props.product.launchFee +
          props.product.projectFee +
          props.product.initialFee.min;
    this.state = {
      id: this.generateID(),
      step: 0,
      steps: this.initSteps(props.product.type),
      hours: props.product.hours ? props.product.hours : 0,
      quantity: {
        value: props.product.quantity.min,
        min: props.product.quantity.min,
        max: props.product.quantity.max,
      },
      initialFee: {
        value: props.product.initialFee.min,
        min: props.product.initialFee.min,
        max: props.product.initialFee.max,
      },
      amount: amount,
      addonsTotal: 0,
      addonsList: [],
      totalAmount: amount + amount * 0.05, // + GST Tax,
      adsBudget: props.product.minBudget ? props.product.minBudget : 0,
      product: {
        type: props.product.type,
        ...props.product,
      },
      checkout: {
        title: props.product.checkout.title,
        subtitle: props.product.checkout.subtitle,
      },
      stripe: null,
      calendar: {
        selectedDay: null,
        selectedTime: null,
      },
      project: {
        name: "",
        company: "",
        email: "",
      },
      billing_details: {
        agreement: false,
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        country: "ca",
        zip: "",
      },
    };
  }
  componentDidUpdate() {
    //paypal - to ensure the code doesn’t run unless it’s in the browser.
    PayPalButton = window.paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    });
  }

  generateID = () => {
    return (
      Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    ).toUpperCase();
  };

  initSteps = (type) => {
    switch (type) {
      case "studio-rental":
        return ["details", "calender", "project", "payment"];
      case "digital-advertising":
      case "content-pr-strategy":
      case "hire-cmo":
      case "website-build":
      case "web-it-support":
      case "email-marketing":
        return ["details", "project", "payment"];
      default:
        return ["details", "project", "payment"];
    }
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleStepChanges = (input, value) => {
    if (typeof value === "object") {
      this.setState({
        [input]: {
          ...this.state[input],
          ...value,
        },
      });
    } else {
      this.setState({
        [input]: value,
      });
    }
  };
  updateState = (input, value) => {
    this.setState(
      {
        [input]: value,
      },
      this.calcTotal(value)
    );
  };
  calcTotal = (addonslist) => {
    var addonsTotal = 0;
    addonslist.map((item) => {
      addonsTotal += Number(item[1]) * Number(item[2]);
    });

    this.setState({
      addonsTotal,
    });

    const { amount } = this.state;
    this.setState({
      totalAmount: amount + addonsTotal + (amount + addonsTotal) * 0.05, // + GST Tax
    });
  };
  displayPaymentForm = () => {
    const paymentForm = document.getElementById("payment-form-wrapper");
    paymentForm.classList.remove("hidden");
  };
  //paypal
  createOrder(data, actions) {
    console.log(data, data.product.title);
    return actions.order.create({
      purchase_units: [
        {
          description: data.product.title,
          amount: {
            value: data.totalAmount,
          },
        },
      ],
    });
  }
  onApprove(data, actions) {
    return actions.order.capture().then(function (details) {
      window.location.href = "/thank-you/";
    });
  }
  onError = (data, actions) => {
    console.log("An Error occured with your payment");
    window.location.href = "/error";
  };
  render() {
    const { steps, step } = this.state;
    switch (steps[step]) {
      case "details":
        return (
          <>
            <CheckoutHeading
              title={this.state.checkout.title}
              subtitle={this.state.checkout.subtitle}
            />
            <StepFormDetails
              nextStep={this.nextStep}
              handleStepChanges={this.handleStepChanges}
              updateState={this.updateState}
              state={this.state}
            />
          </>
        );

      case "details-combined":
        return (
          <>
            <CheckoutHeading
              title={this.state.checkout.title}
              subtitle={this.state.checkout.subtitle}
            />
            <StepFormDetailsCombined
              nextStep={this.nextStep}
              prevStep={false}
              handleStepChanges={this.handleStepChanges}
              state={this.state}
            />
          </>
        );
      case "subscription-plan":
        return (
          <>
            <CheckoutHeading
              title={this.state.checkout.title}
              subtitle={this.state.checkout.subtitle}
            />
            <StepSubscriptionPlan
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleStepChanges={this.handleStepChanges}
              state={this.state}
            />
          </>
        );
      case "calender":
        return (
          <>
            <CheckoutHeading
              title={this.state.checkout.title}
              subtitle={this.state.checkout.subtitle}
            />
            <StepEventForm
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleStepChanges={this.handleStepChanges}
              state={this.state}
            />
          </>
        );
      case "project":
        return (
          <>
            <CheckoutHeading
              title={this.state.checkout.title}
              subtitle={this.state.checkout.subtitle}
            />
            <StepProjectDetails
              handleStepChanges={this.handleStepChanges}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              state={this.state}
            />
          </>
        );
      case "payment":
        return (
          <>
            <CheckoutHeading
              title={this.state.checkout.title}
              subtitle={this.state.checkout.subtitle}
            />
            <h3 className="summary-header">Payment Details</h3>
            <CartSummary state={this.state} />
            <div className="choose-method-wrapper">
              <h3 className="summary-header">CHOOSE THE METHOD OF PAYMENT</h3>

              <div className="btn-wrapper">
                <PayPalButton
                  createOrder={(data, actions) =>
                    this.createOrder(this.state, actions)
                  }
                  onApprove={(data, actions) => this.onApprove(data, actions)}
                  onError={(data, actions) => this.onError(data, actions)}
                  style={style}
                />

                <button
                  type="button"
                  onClick={this.displayPaymentForm}
                  className="btn btn-outline"
                >
                  PAY DIRECTLY
                </button>
              </div>
            </div>
            <StepFormPayment
              prevStep={this.prevStep}
              handleStepChanges={this.handleStepChanges}
              state={this.state}
            />
          </>
        );
      default:
        return <>Checkout Form not available</>;
    }
  }
}

export default checkoutSteps;
