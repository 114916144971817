import React from "react";
import EmailSetupOpt1 from "./email-marketing/EmailSetupOpt1";
import EmailSetupOpt2 from "./email-marketing/EmailSetupOpt2";
import EmailSetupOpt3 from "./email-marketing/EmailSetupOpt3";

const EmailMarketingSetupDescription = ({ uid }) => {
  switch (uid) {
    case "EmailSetupOpt1":
      return <EmailSetupOpt1 />;
    case "EmailSetupOpt2":
      return <EmailSetupOpt2 />;
    case "EmailSetupOpt3":
      return <EmailSetupOpt3 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default EmailMarketingSetupDescription;
