import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import {
  CardNumberElement,
  useStripe,
  useElements,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import {
  createPaymentIntents,
  createCustomer,
  createSubscription,
  createSubscriptionPlan,
} from "~services/stripe-service";
import {
  createCalendarTask,
  createHubspotContact,
  createHubspotDeal,
  createHubspotLineItem,
} from "~services/hubspot-service";
import { AsanaSyncData } from "~services/asana-service";

import FormField from "../../../forms/FormField";
import CheckoutError from "./CheckoutError";
import Checkbox from "../../../forms/Checkbox";
import Select from "~core/Select";
import InputMask from "react-input-mask";
import Preloader from "~components/preloader/preloader";
import "./_PaymentForm.scss";

const PaymentForm = ({ prevStep, state, handleStepChanges }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const { totalAmount, amount } = state;
  const { agreement } = state.billing_details;
  const { selectedDay, selectedTime } = state.calendar;
  const { productID, priceID } = state.product.stripe;

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (e) => {
    e.error ? setCheckoutError(e.error.message) : setCheckoutError(null);
  };

  const handleClick = () => {
    agreement
      ? setCheckoutError(null)
      : setCheckoutError("You must accept the agreement");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const billingDetails = {
      name: state.billing_details.name,
      email: state.billing_details.email,
      address: {
        city: state.billing_details.city,
        country: state.billing_details.country,
        line1: state.billing_details.address,
        postal_code: state.billing_details.zip,
      },
    };

    setProcessingTo(true);
    const cardElement = elements.getElement("cardNumber");

    try {
      let clientSecret = null;

      await createPaymentIntents(state)
        .then((res) => {
          clientSecret = res.client_secret;
        })
        .catch((error) => {
          setError(error.message);
        });

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setError(paymentMethodReq.error.message);
      } else {
        // switch (state.product.type) {
        //   case "hire-cmo":
        //     await initSubscription(paymentMethodReq, priceID);
        //     break;
        //   case "digital-advertising":
        //     await initSubscriptionPlan(paymentMethodReq);
        //     break;
        //   default:
        await initDefaultPayment(paymentMethodReq, clientSecret);
        // }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  /**
   *  Default payment method
   */
  const initDefaultPayment = async (paymentMethodReq, clientSecret) => {
    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodReq.paymentMethod.id,
    });

    if (error) {
      setError(error.message);
    } else {
      await onSuccessfulCheckout(paymentMethodReq.paymentMethod);
    }
  };

  /**
   * Stripe subscriptions
   */
  const initSubscription = async (paymentMethodReq, priceId) => {
    await createCustomer(state)
      .then((res) => {
        const customerId = res.id;

        createSubscription({
          customerId: customerId,
          paymentMethodId: paymentMethodReq.paymentMethod.id,
          priceId: priceId,
        })
          .then((subscription) => {
            if (subscription.status === "active") {
              onSuccessfulCheckout(paymentMethodReq.paymentMethod);
            } else {
              setCheckoutError(subscription.message);
              setProcessingTo(false);
            }
          })
          .catch((error) => {
            setError(error.message);
          });
      })
      .catch((error) => {
        console.log(error.message);
        setError("Payment system error");
      });
  };

  /**
   * Stripe subscriptions with custom plan
   */
  const initSubscriptionPlan = async (paymentMethodReq) => {
    await createSubscriptionPlan({
      amount: totalAmount * 100,
      productId: productID,
    })
      .then((plan) => {
        initSubscription(paymentMethodReq, plan.id);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const onSuccessfulCheckout = async (stripePaymentData) => {
    try {
      await createHubspotContact(state, stripePaymentData);
      await createHubspotDeal(state, stripePaymentData);
      await createHubspotLineItem(state, stripePaymentData);

      await AsanaSyncData(state);
      // Create HubSpot calendar task

      if (selectedDay !== null && selectedTime !== null) {
        await createCalendarTask();
      }
    } catch (err) {
      console.log(err);
      console.log("BIG ERROR");
    }

    await navigate("/thank-you");
  };

  const setError = (message) => {
    setCheckoutError(message);
    setProcessingTo(false);
  };

  const iframeStyles = {
    base: {
      // padding: "10px",
      color: "#838383",
      fontSize: "20px",
      fontWeight: "400",
      iconColor: "#ccc",
      // borderBottom: "1px solid #ccc",
      // ":-webkit-autofill": {
      //   color: "#efefef",
      // },
      "::placeholder": {
        color: "#efefef",
      },
      "@media (max-width: 600px)": {
        color: "#ff0000 !important",
      },
    },

    empty: {},
    invalid: {
      iconColor: "#bf2222",
      color: "#bf2222",
    },
    complete: {
      iconColor: "#cbf4c9",
    },
  };

  const cardNumberOptions = {
    style: iframeStyles,
    showIcon: true,
    iconStyle: "default",
    placeholder: "Credit Card Number",
  };

  const cardExpiryOptions = {
    style: iframeStyles,
    placeholder: "Expiry",
  };

  const cardCvcOptions = {
    style: iframeStyles,
    placeholder: "CVC",
  };

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div className="payment-form-wrapper hidden" id="payment-form-wrapper">
      <form onSubmit={handleFormSubmit} className="checkout-form">
        <div className="formRow">
          <div className="col">
            <FormField
              name="email"
              type="email"
              placeholder="Email"
              value={state.billing_details.email}
              onChange={(e) =>
                handleStepChanges("billing_details", {
                  [e.target.name]: e.target.value,
                })
              }
              required
              readonly={isProcessing || !stripe}
            />
          </div>

          <div className="form-control col">
            <InputMask
              name="phone"
              mask="(999) 999-9999"
              placeholder="Phone format: (xxx) xxx-xxxx"
              value={state.billing_details.phone}
              onChange={(e) =>
                handleStepChanges("billing_details", {
                  [e.target.name]: e.target.value,
                })
              }
              readOnly={isProcessing || !stripe}
            />
          </div>
        </div>
        <div className="formRow">
          <div className="col">
            <FormField
              name="name"
              type="text"
              placeholder="Name on Card"
              value={state.billing_details.name}
              onChange={(e) =>
                handleStepChanges("billing_details", {
                  [e.target.name]: e.target.value,
                })
              }
              required
              readonly={isProcessing || !stripe}
            />
          </div>
          <div className="col">
            <CardNumberElement
              options={cardNumberOptions}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="formRow">
          <div className="col">
            <CardExpiryElement
              options={cardExpiryOptions}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <CardCvcElement options={cardCvcOptions} onChange={handleChange} />
          </div>
        </div>
        <div className="formRow">
          <div className="col">
            <FormField
              name="address"
              type="text"
              placeholder="Billing Address"
              value={state.billing_details.address}
              onChange={(e) =>
                handleStepChanges("billing_details", {
                  [e.target.name]: e.target.value,
                })
              }
              required
              readonly={isProcessing || !stripe}
            />
          </div>
          <div className="col">
            {" "}
            <FormField
              name="city"
              label="City"
              type="text"
              placeholder="City"
              value={state.billing_details.city}
              onChange={(e) =>
                handleStepChanges("billing_details", {
                  [e.target.name]: e.target.value,
                })
              }
              required
              readonly={isProcessing || !stripe}
            />
          </div>
        </div>

        <div className="formRow">
          <div className="col">
            <Select
              name="country"
              placeholder="Country"
              value={{ option: "ca", label: "Canada" }}
              options={[]}
              //onChange={e => handleStepChanges('billing_details', {[e.target.name]: e.target.value})}
              disabled={isProcessing || !stripe}
              required
            />
          </div>
          <div className="col">
            <div className="form-control">
              <InputMask
                style={{ textTransform: "uppercase" }}
                name="zip"
                mask="a9a-9a9"
                placeholder="Postal / Zip Code"
                required
                value={state.billing_details.zip}
                onChange={(e) =>
                  handleStepChanges("billing_details", {
                    [e.target.name]: e.target.value,
                  })
                }
                readOnly={isProcessing || !stripe}
              />
            </div>
          </div>
        </div>
        <Checkbox
          name="agreement"
          label="I completely understand and am confirming that I know what I am paying for and accept the deliverables as described."
          required
          onChange={(e) =>
            handleStepChanges("billing_details", {
              [e.target.name]: e.target.checked,
            })
          }
        />
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        <div className="checkout-nav">
          {/* TIP always disable your submit button while processing payments */}
          <button type="button" className="btn btn-prev" onClick={Back}>
            <i className="font-icon arrow-right"> </i> Back
          </button>
          <button
            disabled={!agreement || isProcessing || !stripe}
            className="btn btn-blue"
            onClick={handleClick}
          >
            {isProcessing ? "Processing..." : `Pay $${totalAmount.toFixed(2)}`}
          </button>
        </div>
      </form>
      <Preloader enabled={isProcessing} />
    </div>
  );
};

export default PaymentForm;
