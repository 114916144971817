const api = `${process.env.GATSBY_API_STRIPE}`;
export const createPaymentIntents = (state) => {
  return fetch(`${api}/createPaymentIntents`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      amount: state.totalAmount * 100,
      description: `${state.product.title} | ${state.product.subtitle}`,
      metadata: {
        product: state.product.title,
        type: state.product.category,
        amount: state.totalAmount,
        currency: "cad",
        description: state.product.subtitle,
        quantity: state.quantity.value,
      },
    }),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((error) => error.message);
};

export const createCustomer = (state) => {
  return fetch(`${api}/createCustomer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: state.billing_details.name,
      email: state.billing_details.email,
      phone: state.billing_details.phone,
      address: {
        line1: state.billing_details.address,
        city: state.billing_details.city,
        country: state.billing_details.country,
        postal_code: state.billing_details.zip,
      },
    }),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error.message);
};

export const createSubscription = (subscription) => {
  return fetch(`${api}/createSubscription`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(subscription),
  })
    .then((response) => response.json())
    .then((result) => result);
};

export const createSubscriptionPlan = (plan) => {
  return fetch(`${api}/createSubscriptionPlan`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(plan),
  })
    .then((response) => response.json())
    .then((result) => result);
};
