import React from "react";
import { Link } from "gatsby";

const EmailSetupOpt3 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>1 x 60-minute discovery call with our team</li>
      <li>
        3 x custom personas [research & creation, as well as persona
        segmentation creation within email marketing tool post-approval]
      </li>
      <li>1 journey map to include all 3 personas</li>
      <li>Tech stack recommendation</li>
      <li>
        1 x 60-minute review call with our team [personas, journey map +
        recommendations]
      </li>
      <li>CRM setup or update [if current CRM works] & integration</li>
      <li>
        Email marketing tool setup or update [if current tool works] &
        integration (max 3 integrations)
      </li>
      <li>6 additional hours of integration & automation setup & support</li>
      <li>
        3 x email templates [1 newsletter template, 1 basic/all-purpose
        template, & 1 promo template]
      </li>
      <li>
        5 x automated flows including:
        <ul className="ul-circle" style={{ "margin-bottom": 0 }}>
          <li>Email content plan & strategy for 3-6 emails per flow</li>
          <li>
            Email flow content outlines [you write the copy, if copywriting is
            needed from our team, we bill at an additional $85 + GST/hr]
          </li>
          <li>Creation of all email graphics</li>
          <li>Creation of all emails in email tool</li>
          <li>
            Creation of automated workflows, including integrations + triggers
          </li>
          <li>Pre-flight bug check & live launch</li>
        </ul>
      </li>
      <li>
        60-minute recorded training session on how the tools interact with each
        other, as well as how to create new emails and automated workflows,
        using templates & personas.
      </li>
    </ul>
    <p className="text-small">
      <i>
        Note: This package does not include the costs of any of the tools (ie./
        ActiveCampaign, Zapier, etc.) required to build your tech stack. Those
        are to be purchased directly on your credit card. Should additional
        assets be required, they will be quoted and billed based on our hourly
        rates{" "}
        <Link to="/services/email-automated-marketing-services/">here</Link>.
      </i>
    </p>
  </>
);
export default EmailSetupOpt3;
