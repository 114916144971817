import React, { useEffect, useState } from "react";
import Quantity from "../../components/quantity/quantity";
import Description from "../../components/description/description";
import LaunchFeeInfo from "../../components/description/LaunchFeeInfo";
import ProjectFeeInfo from "../../components/description/ProjectFeeInfo";
import Addons from "../../components/addons/addons";
import Modal from "../../../modal/modal";
import CartSummary from "../cart-summary";
import StipeInfo from "../../components/description/stripe-info";
import "./_details.scss";
import Checkbox from "../../../forms/Checkbox";

const StepFormDetails = ({
  state,
  nextStep,
  handleStepChanges,
  updateState,
}) => {
  const [error, setError] = useState({});
  const [checked, setChecked] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [hasAddon, setHasAddon] = useState(false);
  const {
    type,
    uid,
    checkout: { stepCalcText, stepAgreementText },
  } = state.product;

  function createFinePrintInner() {
    return { __html: state.product.checkout.finePrint };
  }

  const finePrintSection = (
    <div dangerouslySetInnerHTML={createFinePrintInner()} />
  );

  useEffect(() => {
    switch (type) {
      default:
        setAgreement(true);
        setChecked(false);
    }
  }, []);

  // check to see which products has addons available
  useEffect(() => {
    switch (type) {
      case "website-build":
      case "email-marketing-setup":
      case "email-marketing-monthly":
      case "web-it-support":
      case "seo":
        setHasAddon(true);
        break;
      default:
        setHasAddon(false);
    }
  }, []);

  const Continue = (e) => {
    e.preventDefault();

    state.quantity.value > 0 && checked
      ? nextStep()
      : setError({ quantity: "Value must be greater than 0" });
  };
  const isActiveQuantity = state.product.quantity.enabled;
  return (
    <>
      <div className="checkout-form-details">
        <div className="checkout-desc">
          <Description type={type} uid={uid ? uid : null} />
        </div>
        {/* show addons if product has any available addons */}
        {hasAddon && (
          <>
            {isActiveQuantity && (
              <Quantity
                stepCalcText={stepCalcText}
                state={state}
                handleStepChanges={handleStepChanges}
                error={error}
                displaytotal={false}
              />
            )}
            <Addons
              type={type}
              state={state}
              handleStepChanges={handleStepChanges}
              updateState={updateState}
              error={error}
            />
            <StipeInfo />
            <h3 className="summary-header">PROJECT TOTAL</h3>
            <CartSummary state={state} />
          </>
        )}

        {!hasAddon && isActiveQuantity && (
          <>
            <Quantity
              stepCalcText={stepCalcText}
              state={state}
              handleStepChanges={handleStepChanges}
              error={error}
              displaytotal={true}
            />
            <StipeInfo />
          </>
        )}

        {state.product.launchFee ? (
          <LaunchFeeInfo uid={state.product.uid} />
        ) : (
          ""
        )}

        {state.product.projectFee ? (
          <ProjectFeeInfo uid={state.product.uid} />
        ) : (
          ""
        )}
        {!hasAddon && !isActiveQuantity && <StipeInfo />}

        <div className="checkbox-agreement-wrapper">
          {agreement && (
            <>
              <Modal title="THE FINE PRINT" button="Read the fine print here">
                {finePrintSection}
              </Modal>
              <Checkbox
                name="agree"
                className={"checkout-desc-agree"}
                defaultChecked={false}
                label={stepAgreementText}
                required
                onChange={() => setChecked(!checked)}
              />
            </>
          )}
        </div>
      </div>

      <div className="checkout-nav">
        <button
          type="button"
          className="btn btn-next"
          disabled={agreement && !checked}
          onClick={Continue}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default StepFormDetails;
