import React from "react";

const StudioFullDayOpt3 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      The whole enchilada! With the full support of our team, all the equipment
      you could possibly need, and a pre-production planning meeting, we’ve got
      you covered so that you can do what you do best – shine bright in your
      show/presentation/podcast/webinar/event!
    </p>
    <p>
      <i>
        This booking will include 8 hours of recording time plus 1 hour of
        pre-booking time to ensure microphones are connected and attached to
        you/your guests, cameras and lighting are adapted for your guests, etc,
        for a total booking time of 9 hours.
      </i>
    </p>
  </>
);

export default StudioFullDayOpt3;
