import React from "react";

const StudioHourlyOpt1 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>B.Y.O.E = Bring Your Own Equipment</p>
    <p>
      This means that you are 100% responsible for bringing everything that you
      need to run your show/presentation/interview, and we are just providing
      you with the (thoughtfully crafted) room to set up in.
    </p>
    <p>
      <i>
        2 hour minimum to ensure that you have time to setup and tear down your
        equipment and production.
      </i>
    </p>
  </>
);

export default StudioHourlyOpt1;
