import React from "react";
import EmailMonthlyOpt1 from "./email-marketing/EmailMonthlyOpt1";
import EmailMonthlyOpt2 from "./email-marketing/EmailMonthlyOpt2";
import EmailMonthlyOpt3 from "./email-marketing/EmailMonthlyOpt3";

const EmailMarketingMonthlyDescription = ({ uid }) => {
  switch (uid) {
    case "EmailMonthlyOpt1":
      return <EmailMonthlyOpt1 />;
    case "EmailMonthlyOpt2":
      return <EmailMonthlyOpt2 />;
    case "EmailMonthlyOpt3":
      return <EmailMonthlyOpt3 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default EmailMarketingMonthlyDescription;
