import React from "react";

const DigitalAdvertisingOpt1 = () => (
  <>
    <h5 className="heading">MONTHLY DELIVERABLES:</h5>
    <ul>
      <li>Monthly content goals & messaging strategy</li>
      <li>2 social posts a week on key channels</li>
      <li>Scheduling of all social content through Later</li>
      <li>Monthly check-in meeting</li>
      <li>
        4 Instagram/Facebook stories per month, to be planned & scheduled for
        highest impact
      </li>
    </ul>

    <p>
      <strong>
        <i>
          We recommend an initial 3-month agreement to measure brand-content
          alignment & success.
        </i>
      </strong>
    </p>
  </>
);
export default DigitalAdvertisingOpt1;
