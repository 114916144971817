import React from "react";

const WebItSupportOpt4 = () => (
  <>
    <p>
      <strong>
        Please note, this add-on is only available to clients who have a
        pre-existing, active monthly or quarterly support retainer package with
        us.
      </strong>{" "}
      If you purchase this package and do not have a retainer with us, you will
      be unable to use these hours until a retainer package is purchased.{" "}
    </p>
  </>
);
export default WebItSupportOpt4;
