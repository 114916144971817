import React from "react";

const SmallSEOPackage = () => (
  <>
    <h5 className="heading">MONTHLY DELIVERABLES:</h5>
    <ul>
      <li>1 x 15-minute strategy review call</li>
      <li>Keyword research</li>
      <li>Competitor research</li>
      <li>Monthly content strategy, goals & plan, including benchmarking</li>
      <li>1 x hour of webmaster & IT support for technical SEO improvements</li>
      <li>
        2 x hours of SEO content writing services for improvements on existing
        copy
      </li>
      <li>30 minutes of backlink creation support</li>
      <li>Monthly site health check (content & technical)</li>
      <li>1 x 15-minute monthly debrief call</li>
    </ul>
  </>
);
export default SmallSEOPackage;
