import React from "react";
import AddonsItems from "../../../products/AddonsItems";

const EmailSetupAddons = ({ state, handleStepChanges, updateState }) => {
  return (
    <section className="">
      <AddonsItems
        type="outline"
        state={state}
        handleStepChanges={handleStepChanges}
        updateState={updateState}
      >
        <div
          title="Additional Integrations Support Hours"
          subtitle="$115 per hour"
          id="additional-hourly-integration-support"
          srclink="/services/addons/additional-integrations-support-hours"
          price="115"
          quantity={true}
          interval="1"
        ></div>
        <div
          title="Additional Automated Workflows"
          subtitle=" $1,050 per flow"
          id="additional-automated-workflows"
          srclink="/services/addons/additional-automatedworkflow"
          price="1050"
          quantity={true}
          interval="1"
        ></div>
      </AddonsItems>
    </section>
  );
};

export default EmailSetupAddons;
