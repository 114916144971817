import React from "react";

const StudioFullDayOpt1 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      This means that you are 100% responsible for bringing everything that you
      need to run your show/presentation/interview, and we are just providing
      you with the (thoughtfully crafted) room to set up in.
    </p>
    <p>
      <i>
        This booking will include 8 hours of recording time plus 1 hour of
        setup/tear down for a total booking time of 9 hours.
      </i>
    </p>
  </>
);

export default StudioFullDayOpt1;
