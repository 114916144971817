import React from "react";

const DigitalAdvertisingOpt1 = () => (
  <>
    <h5 className="heading">MONTHLY DELIVERABLES:</h5>
    <ul>
      <li>Monthly campaign goals, campaign messaging & paid strategy</li>
      <li>Keyword research</li>
      <li>
        2 visual ads (tweaked & adjusted for 2 key channels like
        Facebook/Instagram + Google)
      </li>
      <li>3 Google search ads</li>
      <li>Campaign build & launch</li>
      <li>Ongoing weekly campaign management, monitoring & optimization</li>
      <li>
        Monthly debrief on the campaign (evaluating and scoring the results &
        identifying opportunities, learning & changes that need to happen with
        the creative, budget, targeting & channel mix)
      </li>
    </ul>

    <p>
      <strong>
        <i>
          We recommend an initial 3-month agreement to give a clear picture of
          audience & creative success.{" "}
        </i>
      </strong>
    </p>
  </>
);
export default DigitalAdvertisingOpt1;
