import React from "react";
import { Link } from "gatsby";
const WebsiteOpt5 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>Discovery & Content Mapping with our team (60 minutes x 2)</li>
      <li>
        Persona and user journey mapping on top of outlined Content Map (60
        minutes x 2)
      </li>
      <li>Technology and Feature Set Review and Selection (60 minutes x 2)</li>
      <li>U/X Design concept to review and analyze</li>
      <li>Website build up to 100 unique pages + system required pages</li>
      <li>Integration with chosen marketing tech stack (e.g. HubSpot CRM) </li>
      <li>Integration with chosen payment and commerce stack</li>
      <li>Website publishing on your domain of choice.</li>
      <li>180-day post-build webmaster support (up to 5 hours per month)</li>
      <li>
        Curating and Optimizing support for content that will be uploaded to the
        site
      </li>
      <li>Development Staging Environment for the build</li>
      <li>Publishing of live site</li>
    </ul>

    <p className="text-small">
      <i>
        Note: Domain & hosting costs are not covered in this package. If you do
        not have your domain purchased or an appropriate hosting package
        arranged, we can help with that. Our webmaster services bill hourly at
        $200/hr – let us know during our initial call that you need this
        support, and we can invoice you. Alternatively, you can pre-purchase a
        support package <Link to="/services/support">here</Link>.
      </i>
    </p>
  </>
);
export default WebsiteOpt5;
