import React from "react";
import HireCmoOpt1 from "./hire-cmo/HireCmoOpt1";
import HireCmoOpt2 from "./hire-cmo/HireCmoOpt2";
import HireCmoOpt3 from "./hire-cmo/HireCmoOpt3";
import HireCmoOpt4 from "./hire-cmo/HireCmoOpt4";
import HireCmoOpt5 from "./hire-cmo/HireCmoOpt5";
import HireCmoOpt6 from "./hire-cmo/HireCmoOpt6";
import HireCmoOpt7 from "./hire-cmo/HireCmoOpt7";

const HireCmoDescription = ({ uid }) => {
  switch (uid) {
    case "HireCmoOpt1":
      return <HireCmoOpt1 />;
    case "HireCmoOpt2":
      return <HireCmoOpt2 />;
    case "HireCmoOpt3":
      return <HireCmoOpt3 />;
    case "HireCmoOpt4":
      return <HireCmoOpt4 />;
    case "HireCmoOpt5":
      return <HireCmoOpt5 />;
    case "HireCmoOpt6":
      return <HireCmoOpt6 />;
    case "HireCmoOpt7":
      return <HireCmoOpt7 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default HireCmoDescription;
