import React from "react";
import ProductPhotographyDescription from "./product-photography";
import StudioRentalDescription from "./studio-rental";
import WebsiteBuildDescription from "./website-build";
import ContentPRStrategyDescription from "./content-pr-strategy";
import HireCmoDescription from "./hire-cmo";
import WebmasterITSupportDescription from "./webmaster-it-support";
import DigitalAdvertisingDescription from "./digital-advertising";
import DesignServices from "./design-services";
import EmailMarketingSetupDescription from "./email-marketing-setup";
import EmailMarketingMonthlyDescription from "./email-marketing-monthly";
import SEOServicesDescription from "./seo-services";
import SEOAuditServiceDescription from "./seo-audit-service";

const ProductDesc = ({ type, uid }) => {
  const description = () => {
    switch (type) {
      case "product-photography":
        return <ProductPhotographyDescription uid={uid} />;
      case "studio-rental":
        return <StudioRentalDescription uid={uid} />;
      case "website-build":
        return <WebsiteBuildDescription uid={uid} />;
      case "content-pr-strategy":
        return <ContentPRStrategyDescription uid={uid} />;
      case "hire-cmo":
        return <HireCmoDescription uid={uid} />;
      case "web-it-support":
        return <WebmasterITSupportDescription uid={uid} />;
      case "web-it-support-noaddon":
        return <WebmasterITSupportDescription uid={uid} />;
      case "digital-advertising":
        return <DigitalAdvertisingDescription uid={uid} />;
      case "design":
        return <DesignServices uid={uid} />;
      case "email-marketing-setup":
        return <EmailMarketingSetupDescription uid={uid} />;
      case "email-marketing-monthly":
        return <EmailMarketingMonthlyDescription uid={uid} />;
      case "seo":
        return <SEOServicesDescription uid={uid} />;
      case "seo-audit":
        return <SEOAuditServiceDescription uid={uid} />;
      default:
        return <h1>No project match</h1>;
    }
  };

  return (
    <>
      <div>{description()}</div>
    </>
  );
};
export default ProductDesc;
