import React from "react";

const HireCmoOpt5 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      In this 2-hour session, we let the conversation develop and cover
      everything that we may touch on – we basically look at everything related
      to your business over the course of 120 minutes.
    </p>
    <p>
      You should be ready with all of your platforms and dashboards open and a
      willingness to be transparent with us. Our goal is to help you, not make
      you feel bad. Just remember that our experience spans from being active as
      an agency for over a decade, working with hundreds of businesses in almost
      every single industry that you can think of (everything from granola bars
      to HVAC manufacturers) – and that is knowledge we want to share with you
      in a practical and actionable manner.
    </p>
    <p>
      <strong>
        If you really want to launch or grow – this is the best investment you
        can make.
      </strong>
    </p>
    <p>
      <i>
        Please note, additional services like design, copywriting and web
        development can be purchased separately. Paid media spend will be in
        addition.
      </i>
    </p>
  </>
);
export default HireCmoOpt5;
