import React from "react";
import WebItSupportOpt1 from "./web-it-support/WebItSupportOpt1";
import WebItSupportOpt2 from "./web-it-support/WebItSupportOpt2";
import WebItSupportOpt3 from "./web-it-support/WebItSupportOpt3";
import WebItSupportOpt4 from "./web-it-support/WebItSupportOpt4";

const WebmasterITSupportDescription = ({ uid }) => {
  switch (uid) {
    case "webItSupportOpt1":
      return <WebItSupportOpt1 />;
    case "webItSupportOpt2":
      return <WebItSupportOpt2 />;
    case "webItSupportOpt3":
      return <WebItSupportOpt3 />;
    case "WebItAddonOpt1":
      return <WebItSupportOpt4 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default WebmasterITSupportDescription;
