import React from "react";
import DigitalAdvertisingOpt1 from "./digital-advertising/DigitalAdvertisingOpt1";
import DigitalAdvertisingOpt2 from "./digital-advertising/DigitalAdvertisingOpt2";
import DigitalAdvertisingOpt3 from "./digital-advertising/DigitalAdvertisingOpt3";

const DigitalAdvertisingDescription = ({ uid }) => {
  switch (uid) {
    case "DigitalAdvertisingOpt1":
      return <DigitalAdvertisingOpt1 />;
    case "DigitalAdvertisingOpt2":
      return <DigitalAdvertisingOpt2 />;
    case "DigitalAdvertisingOpt3":
      return <DigitalAdvertisingOpt3 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default DigitalAdvertisingDescription;
