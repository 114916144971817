import React from "react";
import { Link } from "gatsby";

const LaunchFeeInfo = ({ uid }) => {
  var info = "";
  switch (uid) {
    case "DigitalAdvertisingOpt1":
    case "DigitalAdvertisingOpt2":
    case "DigitalAdvertisingOpt3":
      info = (
        <p>
          The one-time launch fee covers initial meetings, ensuring we have
          access to all your platforms & accounts, adding all your accounts to
          our reporting tools, initial research & audits, the onboarding process
          & the creation of your project space. This one-time fee is required no
          matter which package you choose. This fee is waived if you commit to
          and pay for 12 months upfront. If you choose{" "}
          <Link className="text-darkblue" to="/services/content">
            a content plan
          </Link>{" "}
          AND a digital advertising plan, this fee is payable only once.
        </p>
      );
      break;
    case "SmallContentPackage":
    case "MediumContentPackage":
    case "LargeContentPackage":
      info = (
        <p>
          The one-time launch fee covers initial meetings, ensuring we have
          access to all your platforms & accounts, adding all your accounts to
          our reporting tools, initial research & audits, the onboarding process
          & the creation of your project space. This one-time fee is required no
          matter which package you choose. This fee is waived if you commit to
          and pay for 12 months upfront. If you choose{" "}
          <Link className="text-darkblue" to="/services/advertising/">
            a digital advertising
          </Link>{" "}
          plan AND a content plan, this fee is payable only once.
        </p>
      );
      break;
    default:
      return null;
  }

  return <>{info}</>;
};

export default LaunchFeeInfo;
