import React, { useEffect, useState } from "react";
import AdvertisingBudget from "./advertisingBudget";
import Description from "../../components/description/description";
import Checkbox from "../../../forms/Checkbox";
import OneTimeLaunchFee from "./oneTimeLaunchFee";

const StepSubscriptionPlan = ({ state, nextStep, handleStepChanges }) => {
  const [error] = useState({});
  const [checked, setChecked] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const Continue = (e) => {
    e.preventDefault();

    nextStep();
  };
  const {
    category,
    type,
    uid,
    checkout: { stepCalcText, stepAgreementText },
  } = state.product;

  useEffect(() => {
    switch (category) {
      case "Digital Advertising Campaigns":
        setAgreement(true);
        break;
      default:
        setChecked(true);
    }
  }, []);

  return (
    <>
      <div className="checkout-form-details">
        <Description type={type} uid={uid ? uid : null} />
        <AdvertisingBudget
          state={state}
          handleStepChanges={handleStepChanges}
          error={error}
        />
        <OneTimeLaunchFee />
        <div className="checkbox-agreement-wrapper">
          {agreement && (
            <Checkbox
              name="agree"
              className={"checkout-desc-agree"}
              defaultChecked={false}
              label={stepAgreementText}
              required
              onChange={() => setChecked(!checked)}
            />
          )}
        </div>
      </div>
      <div className="checkout-nav">
        <button
          type="button"
          className="btn btn-next"
          disabled={agreement && !checked}
          onClick={Continue}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default StepSubscriptionPlan;
