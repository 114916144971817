import React from "react";
import SEOOpt2 from "./seo-services/SEOOpt2";
import SEOOpt3 from "./seo-services/SEOOpt3";
import SEOOpt4 from "./seo-services/SEOOpt4";

const SEOServicesDescription = ({ uid }) => {
  switch (uid) {
    case "SmallSEOPackage":
      return <SEOOpt2 />;
    case "MediumSEOPackage":
      return <SEOOpt3 />;
    case "LargeSEOPackage":
      return <SEOOpt4 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default SEOServicesDescription;
