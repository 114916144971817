import React from "react";
import { Link } from "gatsby";

const DesignOpt3 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>45-minute discovery call with our design team</li>
      <li>Design brief</li>
      <li>
        50 hours of work [tracked by our super fancy project management system]
      </li>
    </ul>
    <p>
      <i>
        Note: The 50 hours includes all time spent on meetings [excluding the
        initial 45-minute call], project management, emails, and revisions. In
        order to maximize the amount of work you’re getting, we recommend
        providing all assets and information upfront so that we don’t have to
        spend half the time emailing you over and over for logo files, copy, or
        colours. This is probably not enough time to create a logo or brand
        identity. If you are unsure how many hours you need, please consider a{" "}
        <Link to="/services/design-services/#one-hour-design-consultation">
          1-hour consultation and design brief
        </Link>{" "}
        as a time-saving starting point.{" "}
      </i>
    </p>
  </>
);
export default DesignOpt3;
