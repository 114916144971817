import React from "react";
import { Link } from "gatsby";
const WebsiteOpt2 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>Discovery & project kickoff with our UX team (45 mins)</li>
      <li>Sitemap </li>
      <li>
        Complete SEOptimized site copy for 8 pages PLUS fine print page (such as
        a privacy policy), and 404 error page{" "}
      </li>
      <li>3 custom UX design concepts to choose from </li>
      <li>
        UX design for 8 pages, including a blog template if needed PLUS 1
        template for a fine print page (such as a privacy policy), and 1 404
        error page
      </li>
      <li>Dev staging area during build process</li>
      <li>
        Website build for 8 pages PLUS 1 template for a fine print page (such as
        a privacy policy), and 1 404 error page
      </li>
      <li>Website publishing on your domain of choice</li>
      <li>
        Installation of Yoast (SEO plugin), plus Google Tag Manager & Google
        Analytics (does not include account setup for Google)
      </li>
      <li>SEO Audit</li>
      <li>60-day post-build webmaster support (up to 3 hours)</li>
    </ul>

    <p className="text-small">
      <i>
        Note: Domain & hosting costs are not covered in this package. If you do
        not have your domain purchased or an appropriate hosting package
        arranged, we can help with that. Our webmaster services bill hourly at
        $200/hr – let us know during our initial call that you need this
        support, and we can invoice you. Alternatively, you can pre-purchase a
        support package <Link to="/services/support">here</Link>.
      </i>
    </p>
  </>
);
export default WebsiteOpt2;
