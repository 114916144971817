import React from "react";
import DecrementButton from "../../components/buttons/decrementButton";
import IncrementButton from "../../components/buttons/incrementButton";

const AdvertisingBudget = ({ state, handleStepChanges }) => {
  const { amount, adsBudget } = state;
  // console.log(state);
  return (
    <div className="checkout-quantity-column">
      <h4 className="heading">PROJECT DETAILS</h4>
      <div className="checkout-quantity-component">
        <div className="checkout-quantity">
          <div className="items-count-group">
            <DecrementButton
              onClick={() => {
                const { minBudget } = state.product;
                if (adsBudget > minBudget) {
                  handleStepChanges("adsBudget", adsBudget - 500);
                  handleStepChanges("amount", amount - 500);
                }
              }}
            />
            <input
              type="text"
              name="adsBudget"
              minLength="4"
              value={adsBudget.toLocaleString()}
              readOnly={true}
            />
            <IncrementButton
              onClick={() => {
                handleStepChanges("adsBudget", adsBudget + 500);
                handleStepChanges("amount", amount + 500);
              }}
            />
          </div>
          <p>Your advertising budget</p>
        </div>
        <div className="checkout-amount">
          <input
            type="text"
            id="amount"
            value={`$${amount.toLocaleString()}`}
            readOnly={true}
          />
          <p>Total (+tax)</p>
        </div>
      </div>
    </div>
  );
};

export default AdvertisingBudget;
