import React from "react";
import { Link } from "gatsby";
import AddonsItems from "../../../products/AddonsItems";

const WebsiteAddons = ({ state, handleStepChanges, updateState }) => {
  return (
    <section className="">
      <AddonsItems
        type="outline"
        state={state}
        handleStepChanges={handleStepChanges}
        quantity={false}
        updateState={updateState}
      >
        <div
          title="Visual Language Package"
          subtitle="$1,870 CAD"
          id="addon-visual-language-package"
          price="1870"
        >
          Our visual language package is designed to build out your brand if
          you’ve got a logo and not much else. Includes a Visual Language Guide,
          templates for social, a collection of stock images & graphics, and
          your choice of 2 communication templates (please see product
          description for more details). Does not include logo design. Want to
          see the impact of visual language on a website?{" "}
          <Link to="/services/addons/visual-language-package">
            Full add-on details here.
          </Link>
        </div>
        <div
          title="CRM Setup & Integration"
          subtitle="$850 CAD"
          id="addon-crm-setup"
          price="850"
        >
          Want a CRM but unsure where to start? Our inbound specialist will work
          with you to find the best CRM fit for your business, get the account
          set up for you, and then work with our web team to ensure smooth
          integration between your website and new CRM. This package does not
          include the cost of the CRM or any paid plugins needed.
          <Link to="/services/addons/crm-setup-integration">
            Full add-on details here.
          </Link>
        </div>
      </AddonsItems>
    </section>
  );
};

export default WebsiteAddons;
