import React from "react";

const StudioHourlyOpt3 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      The whole enchilada! With the full support of our team, all the equipment
      you could possibly need, and a pre-production planning meeting, we’ve got
      you covered so that you can do what you do best – shine bright in your
      show/presentation/podcast/webinar/event!
    </p>
  </>
);

export default StudioHourlyOpt3;
