import React from "react";

const ProductPhotosOpt2 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      Essentially, the brainpower and creative talent of our photo team (our
      Creative Director, Head of Photography and Shoot Stylist) to make your
      product photos really stand out and help nail down your product
      photography style, identity, and aesthetic. The first part of this process
      will be an intake form where we will learn everything we can about your
      products and brand. Then we will digest all that information and give you
      customized recommendations on everything from lighting to styling to
      angles to 360° video in a one-on-one meeting with moodboards.
    </p>
    <p>
      <i>
        This fee does not include the shooting or styling of your photos or
        videos and does not cover any additional work you may choose to move
        forward with from our design, styling or photo teams.
      </i>
    </p>
  </>
);
export default ProductPhotosOpt2;
