import React from "react";

const HireCmoOpt7 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      In this session you are inviting us into your most precious dashboards to
      immediately analyse and help you turn the growing pile of numbers into
      decision making tools for your business.
    </p>
    <p>
      From building and running your own store, to building a customer and email
      marketing database, to trying to leverage that data with your very own
      social media and search advertising – there is no shortage of data. Every
      single app and platform will try to show you a bunch of numbers and
      dashboards to make you spend more money with them.
    </p>
    <p>
      <strong>
        The Simple Department is here to help you understand your data and learn
        how to use it to make smart, actionable decisions.{" "}
      </strong>
    </p>
    <p>
      <i>
        Please note, additional services like design, copywriting and web
        development can be purchased separately. Paid media spend will be in
        addition.
      </i>
    </p>
  </>
);
export default HireCmoOpt7;
