import React from "react";
import { Link } from "gatsby";

const ProjectFeeInfo = ({ uid }) => {
  var info = "";
  switch (uid) {
    case "EmailMonthlyOpt1":
    case "EmailMonthlyOpt2":
    case "EmailMonthlyOpt3":
      info = (
        <>
          <p style={{ "margin-bottom": "20px" }}>
            The one-time project fee ensures we have access to all your
            platforms & accounts (website & social, so we can review engagement
            levels), the onboarding process, the creation of your project space,
            and the design and build of 1 newsletter email template and 1 promo
            email template. This one-time fee is required no matter which
            package you choose,{" "}
            <strong>
              unless you have also purchased one of our email and automated
              marketing setup packages prior.
            </strong>{" "}
            This fee is waived if you commit to and pay for 12 months upfront.{" "}
          </p>
          <p className="text-small">
            <i>
              This package does not include the costs of any of the tools (ie./
              ActiveCampaign, Zapier, etc.) required for your system – those
              should be purchased directly on your credit card. Should
              additional assets be required, they will be quoted and billed
              based on our hourly rates{" "}
              <Link to="/services/email-automated-marketing-services/">
                here
              </Link>{" "}
              or you can choose from{" "}
              <Link to="/services/email-automated-marketing-services/#email-marketing-addons">
                our add-on menu
              </Link>
              . This package assumes that you have a functional email marketing
              tool in place and{" "}
              <strong>
                does not include any setup or integration work if you do not.
              </strong>{" "}
              If you do not have a functional system in place, please choose
              from one of{" "}
              <Link to="/services/email-automated-marketing-services/#email-marketing">
                our setup packages
              </Link>{" "}
              to start.
            </i>
          </p>
        </>
      );
      break;
    default:
      return null;
  }
  return <>{info}</>;
};

export default ProjectFeeInfo;
