import React from "react";

const HireCmoOpt2 = () => (
  <>
    <h5 className="heading">WHAT YOU ARE BUYING</h5>
    <p>
      When you hire a fractional CMO, you are getting a strategist, project
      manager, campaign manager, budget manager, production manager, brand
      manager, marketing stack consultant, and sounding board. Essentially,
      you’re getting someone who is as excited about your business as you are
      and has the skills to launch your idea into the stratosphere. Your CMO
      will be attached to your business for 3 months with this package.
    </p>

    <p>
      <i>
        Please note, additional services like design, copywriting and web
        development can be purchased following our initial consultation
        meetings, once strategy & direction have been established. Paid media
        spend will be in addition to this project fee.
      </i>
    </p>
  </>
);
export default HireCmoOpt2;
