import React from "react";
import { Link } from "gatsby";
const WebsiteOpt3 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>Discovery & project kickoff with our UX team (45 mins)</li>
      <li>User Journey</li>
      <li>Sitemap</li>
      <li>3 custom UX design concepts to choose from</li>
      <li>
        UX design for 8 core pages, including a blog template if needed PLUS 1
        category page template, 1 product template page, 1 template for a fine
        print page (such as a shipping info page), 1 404 error page and all the
        checkout/cart pages necessary for a smooth shopping experience.
      </li>
      <li>Dev staging area during build process </li>
      <li>
        Website build for 8 pages PLUS 1 category page template, 1 product
        template page, 1 template for a fine print page (such as a shipping info
        page), 1 404 error page and all the checkout/cart pages necessary for a
        smooth shopping experience.
      </li>
      <li>Website publishing on your domain of choice.</li>
      <li>
        Installation of Yoast (SEO plugin), plus Google Tag Manager & Google
        Analytics (does not include account setup for Google)
      </li>
      <li>
        Basic setup of 6 automated transaction emails that are done through the
        ecommerce platform (not external marketing platforms like HubSpot,
        MailChimp, etc). Does not include the copy for the emails.
      </li>
      <li>
        Setup for PayPal and Stripe (which includes Apple Pay and Google Pay)
        integrations. Does not include initial account setup or fees for those
        tools.{" "}
      </li>
      <li>SEO Audi</li>
      <li>60-day post-build webmaster support (up to 3 hours)</li>
    </ul>

    <p className="text-small">
      <i>
        Note: Domain & hosting costs are not covered in this package. If you do
        not have your domain purchased or an appropriate hosting package
        arranged, we can help with that. Our webmaster services bill hourly at
        $200/hr – let us know during our initial call that you need this
        support, and we can invoice you. Alternatively, you can pre-purchase a
        support package <Link to="/services/support">here</Link>.
      </i>
    </p>
  </>
);
export default WebsiteOpt3;
