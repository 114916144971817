import React, { useState, useEffect, useRef } from "react";
import AddAddonsBtn from "../checkout/components/buttons/addAddonsBtn";
import DecrementButton from "../checkout/components/buttons/decrementButton";
import IncrementButton from "../checkout/components/buttons/incrementButton";
import "./_products.scss";

const Desc = ({ children }) => {
  const [height, setHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);
  const onClick = () => {
    setVisible(!visible);
  };

  return (
    <div className="product-desc" ref={elementRef}>
      {height > 250 && window.innerWidth < 1700 ? (
        <>
          <div className={visible ? "" : "product-desc-limit"}>{children}</div>
          <button onClick={onClick} className="btn-toggle">
            {visible ? "Show less" : "Show more"}
          </button>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

const AddonsItems = ({ children, type, updateState }) => {
  var wrapperClass =
    type === "slim"
      ? "product-component-slim product-accordion"
      : type === "outline"
      ? "product-component-outline product-accordion addon-item-wrapper"
      : "product-component product-accordion";
  const childes = React.Children.toArray(children);
  const [addonQuantity, setAddonQuantity] = useState([]);

  useEffect(() => {
    updateState("addonsList", [...addonQuantity]);
  }, [addonQuantity]);

  //handle add addons to project
  const handleAddonsClick = (price, index, hasQuantity, title) => {
    // if it has no quantity it should add to the array
    if (!hasQuantity) {
      // add the addon to the array
      var isNewAddon = -1;
      if (addonQuantity.length > 0) {
        for (let i = 0; i < addonQuantity.length; i++) {
          if (addonQuantity[i][0] === index) {
            isNewAddon = addonQuantity.indexOf(addonQuantity[i]);
          }
        }
      }
      if (isNewAddon === -1) {
        //add addon to the array
        setAddonQuantity((addonQuantity) => [
          ...addonQuantity,
          [index, 1, price, title],
        ]);
      } else {
        //remove addon from array
        removeAddon(isNewAddon, addonQuantity, setAddonQuantity);
      }
    }
    updateState("addonsList", [...addonQuantity]);
  };

  var QuantityIndex;
  const getItemIndex = (id) => {
    //returns -1 if this id is not in the quantity array
    QuantityIndex = -1;
    for (let i = 0; i < addonQuantity.length; i++) {
      if (addonQuantity[i][0] === id) {
        QuantityIndex = i;
      }
    }
    return QuantityIndex;
  };
  const getItemQuantity = (id) => {
    let indexInArray = getItemIndex(id);
    if (indexInArray === -1) {
      return 0;
    } else {
      return addonQuantity[indexInArray][1];
    }
  };
  const removeAddon = (itemIndex, myArray, setArray) => {
    setArray([
      ...myArray.slice(0, itemIndex),
      ...myArray.slice(itemIndex + 1, myArray.length),
    ]);
  };

  const handleVariableAddonIncrement = (id, interval, price, title) => {
    getItemIndex(id);
    // // we are adding the addon to quantity array
    if (QuantityIndex === -1) {
      setAddonQuantity((addonQuantity) => [
        ...addonQuantity,
        [id, interval, price, title],
      ]);
    }
    //  we are incrementing the addon quantity in the  array
    else {
      var newArray = addonQuantity;
      if (newArray[QuantityIndex][1] < 60) {
        newArray[QuantityIndex][1] = newArray[QuantityIndex][1] + interval;
        setAddonQuantity(newArray);
      }
    }
    handleAddonsClick(price, id, true, title);
  };

  const handleVariableAddonDecrement = (id, interval, price, title) => {
    let index = getItemIndex(id);

    if (index === -1) {
      return null;
    }
    //decrement the number
    var newArray = addonQuantity;
    if (newArray[QuantityIndex][1] > interval) {
      newArray[QuantityIndex][1] = newArray[QuantityIndex][1] - interval;
      setAddonQuantity(newArray);
    }
    //if its zero remove from the array
    else if (newArray[QuantityIndex][1] <= interval) {
      removeAddon(index, addonQuantity, setAddonQuantity);
    }
    handleAddonsClick(price, id, true, title);
  };
  const handleVariableAddonChange = (e, id, price, title, interval) => {
    //let user adds number devisable by interval
    let val = Number(e.target.value);
    if (val % interval !== 0) {
      val = Math.trunc(Number(e.target.value) / interval) * interval + interval;
    }
    val = val < 60 ? val : 60;
    let indexInArray = getItemIndex(id);
    // we are adding the addon to quantity array
    if (indexInArray === -1) {
      setAddonQuantity((addonQuantity) => [
        ...addonQuantity,
        [id, val, price, title],
      ]);
    }
    //  we are incrementing the addon quantity in the array
    else {
      var newArray = addonQuantity;
      newArray[indexInArray][1] = val;
      setAddonQuantity(newArray);
      if (val === 0) {
        removeAddon(indexInArray, addonQuantity, setAddonQuantity);
      }
    }
    handleAddonsClick(price, id, true, title);
  };
  const getBtnCopy = (index) => {
    if (getItemIndex(index) !== -1) {
      return "REMOVE";
    }
    return "ADD TO PROJECT ";
  };

  //call this function to update the array in the state
  const [mystate, setMystate] = useState([]);
  const updateComponent = (id) => setMystate([id]);

  return (
    <div className={wrapperClass + " accordion-component"}>
      <div>
        {childes.map((child, index) =>
          child.props.quantity ? (
            <div
              className="accordion-item accordion-header variable-addon-wrapper"
              key={index}
            >
              <div className="checkout-quantity-vaddon">
                <div className="items-count-group">
                  <DecrementButton
                    onClick={() => {
                      updateComponent(child.props.id);
                      handleVariableAddonDecrement(
                        child.props.id,
                        Number(child.props.interval),
                        child.props.price,
                        child.props.title
                      );
                    }}
                  />
                  <input
                    type="number"
                    value={
                      addonQuantity[getItemIndex(child.props.id)]
                        ? getItemQuantity(child.props.id)
                        : 0
                    }
                    onChange={(e) => {
                      updateComponent(child.props.id);
                      handleVariableAddonChange(
                        e,
                        child.props.id,
                        child.props.price,
                        child.props.title,
                        Number(child.props.interval)
                      );
                    }}
                    min="0"
                    max="10"
                  />
                  <IncrementButton
                    onClick={() => {
                      updateComponent(child.props.id);
                      handleVariableAddonIncrement(
                        child.props.id,
                        Number(child.props.interval),
                        child.props.price,
                        child.props.title
                      );
                    }}
                  />
                </div>
              </div>
              <div className="accordion-title">
                <h4>
                  {child.props.title} | {child.props.subtitle}
                </h4>
                <p className="subtitle">
                  <a href={child.props.srclink} target="_blank">
                    Learn More
                  </a>
                </p>
                <p className="extrainfo"></p>
              </div>
              <div className="price">
                <Desc>{child.props.children}</Desc>
              </div>
            </div>
          ) : (
            <div
              className="accordion-item accordion-header accordion-header-static"
              key={index}
            >
              <div className="accordion-title">
                <h4>{child.props.title}</h4>
                <p className="subtitle">{child.props.subtitle}</p>
                <p className="extrainfo">{child.props.children}</p>
              </div>
              <AddAddonsBtn
                onClick={() =>
                  handleAddonsClick(
                    child.props.price,
                    child.props.id,
                    false,
                    child.props.title
                  )
                }
                btnCopy={getBtnCopy(child.props.id)}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AddonsItems;
