import React from "react";

const DigitalAdvertisingOpt2 = () => (
  <>
    <h5 className="heading">MONTHLY DELIVERABLES:</h5>
    <ul>
      <li>
        Monthly & quarterly content goals, messaging strategy &
        identification/recommendations of paid sponsorship of organic content
      </li>
      <li>Average of 3 social posts a week on key channels</li>
      <li>Scheduling of all social content through Later</li>
      <li>
        1 SEO optimized blog post a month, published on your website & shared on
        social
      </li>
      <li>Biweekly check-in meetings</li>
      <li>
        8 Instagram/Facebook stories per month, to be planned & scheduled for
        highest impact
      </li>
    </ul>

    <p>
      <strong>
        <i>
          We recommend an initial 6-month agreement to create a cohesive content
          story across all your organic channels.{" "}
        </i>
      </strong>
    </p>
  </>
);
export default DigitalAdvertisingOpt2;
