import React from "react";

const OneTimeLaunchFee = () => {
  return (
    <>
      <div className="onetime-fees-container">
        <p>
          The one-time launch fee covers initial meetings, ensuring we have
          access to all your platforms & accounts, adding all your accounts to
          our reporting tools, initial research & audits, the onboarding process
          & the creation of your project space. This one-time fee is required no
          matter which package you choose. This fee is waived if you commit to
          and pay for 12 months upfront. If you choose a content plan AND a
          digital advertising plan, this fee is payable only once.{" "}
        </p>
        <p>
          <i>
            Note: The minimum value for the ad spend should be set at $500.
            There does not have to be a maximum. The launch fee is not
            adjustable but if someone chooses 12 months or more for the number
            of months part, then the launch fee can be reduced to $0.{" "}
          </i>
        </p>
      </div>
    </>
  );
};
export default OneTimeLaunchFee;
