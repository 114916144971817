import React, { useEffect } from "react";
import FormField from "../../../forms/FormField";
import useForm from "../../components/useForm";
import validate from "../../components/formValidationRules";
import CartSummary from "../../steps/cart-summary";

const StepProjectDetails = ({
  prevStep,
  nextStep,
  state,
  handleStepChanges,
}) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useForm(state.project, validate);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Continue = (e) => {
    e.preventDefault();

    if (handleSubmit(e)) {
      nextStep();
    }
  };

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleValidation = (e) => {
    handleStepChanges("project", {
      [e.target.name]: e.target.value,
    });
    handleChange(e);
  };

  return (
    <>
      <h3 className="summary-header">PROJECT TOTAL</h3>
      <CartSummary state={state} />
      <div className="checkout-project-details">
        <h5 className="heading">Project Details</h5>
        <form className="project-details-body" noValidate>
          <div className="formRow">
            <div className="col">
              <FormField
                name="firstname"
                type="text"
                placeholder="Firstname"
                required="required"
                onChange={handleValidation}
                onBlur={handleBlur}
                errors={errors.firstname}
                touched={touched}
                isSubmitting={isSubmitting}
              />
            </div>
            <div className="col">
              <FormField
                name="lastname"
                type="text"
                placeholder="Lastname"
                required="required"
                onChange={handleValidation}
                onBlur={handleBlur}
                errors={errors.lastname}
                touched={touched}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>

          <FormField
            name="company"
            type="text"
            value={state.project.company}
            placeholder="Company Name"
            required="required"
            onChange={handleValidation}
            onBlur={handleBlur}
            errors={errors.company}
            touched={touched}
            isSubmitting={isSubmitting}
          />

          {/* <FormField
            name="email"
            type="text"
            value={state.project.email}
            placeholder="Email"
            required="required"
            onChange={handleValidation}
            onBlur={handleBlur}
            errors={errors.email}
            touched={touched}
            isSubmitting={isSubmitting}
          /> */}

          <FormField
            name="name"
            type="text"
            value={state.project.name}
            placeholder="Project Name"
            required="required"
            onChange={handleValidation}
            onBlur={handleBlur}
            errors={errors.name}
            touched={touched}
            isSubmitting={isSubmitting}
          />
        </form>

        <div className="checkout-nav">
          {prevStep && (
            <button type="button" className="btn btn-prev" onClick={Back}>
              <i className="font-icon arrow-right"> </i> Back
            </button>
          )}
          <button type="button" className="btn btn-next" onClick={Continue}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default StepProjectDetails;
