import React from "react";

const WebItSupportOpt1 = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>
        10 hours of support for technical issues/glitches that arise related to
        email, website, hosting, CRM or social
      </li>
      <li>
        5 hours of proactive website management, update requests & reasonable
        changes
      </li>
      <li>Integrated ticketing system to report issues/bugs/updates</li>
    </ul>
  </>
);
export default WebItSupportOpt1;
