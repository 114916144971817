import React from "react";
import WebsiteOpt1 from "./website/WebsiteOpt1";
import WebsiteOpt2 from "./website/WebsiteOpt2";
import WebsiteOpt3 from "./website/WebsiteOpt3";
import WebsiteOpt4 from "./website/WebsiteOpt4";
import WebsiteOpt5 from "./website/WebsiteOpt5";
import WebsiteOpt6 from "./website/WebsiteOpt6";

const WebsiteBuildDescription = ({ uid }) => {
  switch (uid) {
    case "WebsiteOpt1":
      return <WebsiteOpt1 />;
    case "WebsiteOpt2":
      return <WebsiteOpt2 />;
    case "WebsiteOpt3":
      return <WebsiteOpt3 />;
    case "WebsiteOpt4":
      return <WebsiteOpt4 />;
    case "WebsiteOpt5":
      return <WebsiteOpt5 />;
    case "WebsiteOpt6":
      return <WebsiteOpt6 />;
    default:
      return <h1>No project match</h1>;
  }
};

export default WebsiteBuildDescription;
