import React from "react";

const SEOAudit = () => (
  <>
    <h5 className="heading">INCLUDED:</h5>
    <ul>
      <li>1 x 15-minute initial call with our SEO team</li>
      <li>Technical SEO Audit</li>
      <li>On-Page SEO Audit</li>
      <li>Content Audit</li>
      <li>Competitor Audit</li>
      <li>Backlinks Audit</li>
      <li>Content & Technical SEO Improvements & Recommendations</li>
      <li>Identification of Errors & Broken Links</li>
      <li>Top 10 Suggested Keyword Opportunities</li>
      <li>Workback Plan & Quote for Improvements</li>
      <li>1 x 30-minute post-audit review call</li>
    </ul>

    <p>
      <strong>
        <i>
          Please note, this is an audit only. This does not include hours to be
          used for on-site SEO improvements.
        </i>
      </strong>
    </p>
  </>
);
export default SEOAudit;
